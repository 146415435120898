var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay-opacity":"0.6","max-width":"800","value":_vm.openDialog}},[_c('messageBox',{ref:"msgBox"}),_c('setNumber',{ref:"setNumber"}),_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dropbox")])],1),_c('v-col',{staticClass:"card-title-text",staticStyle:{"font-size":"20px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.editing ? "Modifica pacchetto" : "Aggiungi pacchetto"))]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)],1)]},proxy:true}])},[_c('v-card-text',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_vm._v(" Proprietà pacchetto ")]),_c('v-card-text',[_c('v-form',{ref:"user-editor-form",staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Nome","prepend-icon":"mdi-dropbox","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.bundle.source_name),callback:function ($$v) {_vm.$set(_vm.bundle, "source_name", $$v)},expression:"bundle.source_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Descrizione","prepend-icon":"mdi-format-align-justify","error-messages":errors,"success":valid,"required":"","immediate":true},model:{value:(_vm.bundle.description),callback:function ($$v) {_vm.$set(_vm.bundle, "description", $$v)},expression:"bundle.description"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Pagamenti richiesti","prepend-icon":"mdi-currency-eur","error-messages":errors,"success":valid,"required":"","immediate":true},model:{value:(_vm.bundle.required_payments),callback:function ($$v) {_vm.$set(_vm.bundle, "required_payments", $$v)},expression:"bundle.required_payments"}})]}}],null,true)}),_c('v-dialog',{ref:"dialog_start_date",attrs:{"return-value":_vm.date_start,"width":"290px"},on:{"update:returnValue":function($event){_vm.date_start=$event},"update:return-value":function($event){_vm.date_start=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"rules":"required","immediate":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',_vm._g({attrs:{"label":"Data di inizio","prepend-icon":"mdi-calendar-multiple","error-messages":errors,"success":valid,"required":"","clearable":"","readonly":"","immediate":true},model:{value:(_vm.start_date_formatted),callback:function ($$v) {_vm.start_date_formatted=$$v},expression:"start_date_formatted"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.date_start_modal),callback:function ($$v) {_vm.date_start_modal=$$v},expression:"date_start_modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$refs.dialog_start_date.save(_vm.date_start)}},model:{value:(_vm.date_start),callback:function ($$v) {_vm.date_start=$$v},expression:"date_start"}})],1),_c('v-dialog',{ref:"dialog_end_date",attrs:{"return-value":_vm.date_end,"width":"290px"},on:{"update:returnValue":function($event){_vm.date_end=$event},"update:return-value":function($event){_vm.date_end=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"rules":"required","immediate":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',_vm._g({attrs:{"readonly":"","clearable":"","label":"Data di fine","prepend-icon":"mdi-calendar-multiple","error-messages":errors,"success":valid,"immediate":true},model:{value:(_vm.end_date_formatted),callback:function ($$v) {_vm.end_date_formatted=$$v},expression:"end_date_formatted"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.date_end_modal),callback:function ($$v) {_vm.date_end_modal=$$v},expression:"date_end_modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$refs.dialog_end_date.save(_vm.date_end)}},model:{value:(_vm.date_end),callback:function ($$v) {_vm.date_end=$$v},expression:"date_end"}})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',{staticStyle:{"padding-bottom":"0px"}},[_vm._v(" Composizione prodotti ("+_vm._s(_vm.products.length)+") ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"grid-table",attrs:{"must-sort":true,"headers":_vm.headers,"items":_vm.products,"server-items-length":_vm.products.length,"loading":_vm.loadingProducts},scopedSlots:_vm._u([{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.qty)),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.setQuantity(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s("Imposta quantità"))])])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.setValue(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s("Imposta valore"))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.removeProduct(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s("Cancella prodotto"))])])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',{staticStyle:{"padding-bottom":"0px"}},[_vm._v(" Aggiungi prodotto ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.missingProducts,"loading":_vm.loadingMissingProducts,"search-input":_vm.missingProductSearch,"item-text":"name","item-value":"id","prepend-icon":"mdi-bottle-wine","append-icon":"","placeholder":"Scrivi per ricercare","clearable":"","return-object":""},on:{"update:searchInput":function($event){_vm.missingProductSearch=$event},"update:search-input":function($event){_vm.missingProductSearch=$event}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"text-center pa-0 ma-0",attrs:{"disabled":!_vm.missingProduct,"icon":"","dark":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.addProductToBundle()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}],null,true),model:{value:(_vm.missingProduct),callback:function ($$v) {_vm.missingProduct=$$v},expression:"missingProduct"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('v-btn',{staticClass:"ma-2",staticStyle:{"color":"white"},attrs:{"disabled":invalid || !validated,"width":"120","color":"primary"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v("Conferma")]),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","width":"120","color":"secondary"},on:{"click":function($event){return _vm.dismiss()}}},[_vm._v("Annulla")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay-opacity":"0.6","max-width":"800","value":_vm.openDialog}},[_c('messageBox',{ref:"msgBox"}),_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dropbox")])],1),_c('v-col',{staticClass:"card-title-text",staticStyle:{"font-size":"20px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.editing ? "Modifica ticket" : "Apri ticket"))]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.dismiss}},[_vm._v("mdi-close")])],1)],1)],1)]},proxy:true}])},[_c('v-card-text',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-row',[_c('v-col',[_c('v-form',{ref:"user-editor-form",staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Nome","error-messages":errors,"success":valid,"dense":"","required":""},model:{value:(_vm.ticket.title),callback:function ($$v) {_vm.$set(_vm.ticket, "title", $$v)},expression:"ticket.title"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"outlined":"","items":_vm.severities,"item-text":"value","item-value":"id","label":"Livello","error-messages":errors,"success":valid,"dense":"","required":""},model:{value:(_vm.ticket.severity),callback:function ($$v) {_vm.$set(_vm.ticket, "severity", $$v)},expression:"ticket.severity"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"outlined":"","error-messages":errors,"success":valid,"label":"Descrizione"},model:{value:(_vm.ticket.content),callback:function ($$v) {_vm.$set(_vm.ticket, "content", $$v)},expression:"ticket.content"}})]}}],null,true)})],1)],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('v-btn',{staticClass:"ma-2",staticStyle:{"color":"white"},attrs:{"disabled":invalid || !validated,"width":"120","color":"primary"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v("Conferma")]),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","width":"120","color":"secondary"},on:{"click":function($event){return _vm.dismiss()}}},[_vm._v("Annulla")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
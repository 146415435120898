<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="1024"
    :value="openDialog"
  >
    <messageBox ref="msgBox" />

    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-dropbox</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 20px"
                  >{{ editing ? "Modifica ordine" : "Aggiungi ordine" }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <v-card-text>
              <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                <v-row dense>
                  <v-select
                    v-model="order.source_company"
                    :items="companies"
                    dense
                    item-text="company"
                    item-value="id"
                    outlined
                  />
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="order.customer"
                        label="Ragione sociale o nome completo"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="order.first_name"
                        label="Nome"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="order.last_name"
                        label="Cognome"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="order.contact_phone"
                      label="Telefono"
                    ></v-text-field>
                  </v-col>

                  <v-col>
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="order.email"
                        label="Email"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="9">
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="order.address"
                        label="Indirizzo"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      dense
                      v-model="order.business_address"
                      label="Indirizzo societario"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="order.city"
                        label="Città"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="order.postcode"
                        label="Codice postale"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="order.state"
                      label="Stato"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="order.country"
                        label="Nazione"
                        :error-messages="errors"
                        :success="valid"
                        required
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      v-model="order.description"
                      label="Descrizione"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required|numeric"
                      v-slot="{ errors, valid }"
                      :immediate="editing"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="order.number_of_bottles"
                        :error-messages="errors"
                        :success="valid"
                        label="Numero bottiglie"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="order.shipment_value"
                      label="Valore spedizione"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      style="width:250px"
                      outlined
                      dense
                      v-model="order.custom_tracking"
                      label="Tracking"
                      ><template v-slot:append-outer>
                        <v-tooltip bottom
                          ><template v-slot:activator="{ on }"
                            ><v-btn
                              dark
                              v-on="on"
                              icon
                              @click="rebuildTracking()"
                              href="#"
                              ><v-icon
                                size="24"
                                style="padding-bottom:15px"
                                large
                                color="yellow darken-3"
                                >mdi-refresh</v-icon
                              ></v-btn
                            ></template
                          ><span>{{ "Rigenera tracking" }}</span></v-tooltip
                        >
                      </template></v-text-field
                    >
                  </v-col>
                  <v-col>
                    <v-checkbox
                      dense
                      v-model="order.invoice_required"
                      label="Richiede fattura"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      dense
                      v-model="order.shipped"
                      label="Partito"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      dense
                      v-model="order.exportable"
                      label="Esportabile"
                      class="shrink mr-2 mt-0"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="order.customer_notes"
                      label="Note cliente"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      v-model="order.shipping_notes"
                      label="Note spedizioniere"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col>
                    <v-btn
                      :disabled="invalid || !validated"
                      width="120"
                      @click="confirm()"
                      class="ma-2"
                      color="primary"
                      >Conferma</v-btn
                    >
                    <v-btn
                      dark
                      width="120"
                      @click="dismiss()"
                      class="ma-2"
                      color="secondary"
                      >Annulla</v-btn
                    >
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import messageBox from "../components/MessageBox";
import ordersManager from "../apis/orders";
import { customAlphabet } from "nanoid";
const nanoid = customAlphabet('1234567890ABCDEFGHILMNOPQRSTUVZKYJWX', 6)

export default {
  data() {
    return {
      order: null,
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
      editing: false,
      companies: [],
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    messageBox,
  },

  methods: {
    fetchCompanies() {
      return new Promise((resolve, reject) => {
        ordersManager
          .fetchCompanies()
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    show(order) {
      return new Promise((resolve, reject) => {
        this.order = order;
        this.editing = true;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.fetchCompanies()
          .then((res) => {
            this.companies = res;
            this.openDialog = true;
          })
          .catch((err) => reject(err));
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve(this.order);
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    },

    rebuildTracking() {
      this.order.custom_tracking = nanoid(6).toUpperCase();
    },
  },
};
</script>

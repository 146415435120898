<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="600" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <messageBox ref="msgBox" />
    <setString ref="setString" />
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-package-variant</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{ $t("supplier-edit-title") }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>
            <v-card-text>
              <v-btn v-if="true" color="primary" @click="addCategory()">{{
                $t("supplier-edit-add")
              }}</v-btn>
              <v-data-table
                :items-per-page="perPage"
                :must-sort="true"
                :headers="headers"
                :items="suppliers"
                :options.sync="options"
                :server-items-length="totalSuppliers"
                :loading="loading"
                :footer-props="dataTableFooterOptions"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    color="primary"
                    v-if="true"
                    @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon
                    small
                    color="error"
                    v-if="true"
                    @click="deleteItem(item)"
                    >mdi-delete</v-icon
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import productsManager from "../apis/products";
import setString from "../components/SetString";
import messageBox from "../components/MessageBox";
import staticData from "../apis/staticData";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      currentPage: 1,
      perPage: staticData.defautPerPage,
      sortBy: "name",
      sortDesc: false,
      filter: "",
      totalSuppliers: 0,
      suppliers: [],
      headers: [
        {
          text: this.$t("supplier-edit-tbl-name"),
          align: "left",
          sortable: true,
          value: "name"
        },
        { text: "", align: "right", sortable: false, value: "actions" }
      ],
      options: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      loading: false
    };
  },

  components: {
    setString,
    messageBox
  },

  props: ["value"],

  watch: {
    options: {
      handler() {
        this.fetchSupplier();
      },
      deep: true
    }
  },

  mounted() {
    this.fetchSupplier();
  },

  methods: {
    fetchSupplier() {
      this.loading = true;
      this.suppliers = [];
      this.totalSuppliers = 0;
      let ctx = Object.assign({}, this.options);
      ctx.currentPage = ctx.page;
      ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
      ctx.filter = this.filter;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      productsManager
        .fetchSupplier(ctx)
        .then(results => {
          this.totalSuppliers = results.totalCount;
          this.suppliers = results.items;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    closeDialog: function() {
      this.$emit("supplierEditingDone");
      this.$emit("input");
    },

    deleteItem(item) {
      let sourceId = item.id;
      this.$refs.msgBox
        .show(
          this.$t("supplier-edit-remove-text"),
          this.$t("supplier-edit-remove-title")
        )
        .then(() => {
          productsManager
            .removeSupplier(sourceId)
            .then(() => {
              this.fetchSupplier();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editItem(item) {
      let category = JSON.parse(JSON.stringify(item));
      this.$refs.setString
        .show(
          this.$t("supplier-edit-config-title"),
          this.$t("supplier-edit-config-hint"),
          this.$t("supplier-edit-config-text"),
          category.name,
          category.id,
          true,
          false
        )
        .then(event => {
          category.id = event.context;
          category.name = event.value;
          productsManager
            .updateSupplirt(category)
            .then(() => {
              this.fetchSupplier();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    addCategory() {
      this.$refs.setString
        .show(
          this.$t("supplier-edit-add-title"),
          this.$t("supplier-edit-add-hint"),
          this.$t("supplier-edit-add-text"),
          this.$t("supplier-edit-add-default"),
          null,
          true,
          false
        )
        .then(event => {
          productsManager
            .addSupplier({ name: event.value })
            .then(() => {
              this.fetchSupplier();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<template>
  <div>
    <messageBox ref="msgBox" />
    <BundleEditor ref="bundleEditor" />

    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col cols="1" class="pa-0"
            ><v-icon large>mdi-dropbox</v-icon></v-col
          >
          <v-col
            align="center"
            cols="11"
            class="pa-0 card-title-text"
            style="font-size: 20px"
            >Gestione pacchetti</v-col
          >
        </v-row>
      </template>
      <v-card-text>
        <div style="height:20px" />

        <v-row class="text-center" align="center" justify="center"
          ><v-btn v-if="$store.state.hasRight('279')" v-on:click="addBundle()" color="primary" dark
            >Aggiungi pacchetto</v-btn
          >
        </v-row>
 <div style="height:10px" />

        <v-data-table
          :items-per-page="perPage"
          :must-sort="true"
          :headers="bundlesHeaders"
          :items="bundles"
          :options.sync="bundlesOptions"
          :server-items-length="totalBundles"
          :loading="loadingBundles"
          :footer-props="dataTableFooterOptions"
          item-key="id"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                v-if="$store.state.hasRight('280')"
                  x-small
                  text
                  icon
                  color="secondary"
                  @click.stop="editBundle(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>Modifica pacchetto</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                v-if="$store.state.hasRight('281')"
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteBundle(item.id)"
                  v-on="on"
                  ><v-icon>mdi-delete</v-icon></v-btn
                ></template
              ><span>Rimuovi pacchetto</span></v-tooltip
            >
          </template>

          <template v-slot:item.reference_dates="{ item }">
            {{ item.start_date | toLocaleDate }} -
            {{ item.end_date | toLocaleDate }}
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </div>
</template>

<script>
import BundleEditor from "../../components/BundleEditor";

import staticData from "../../apis/staticData";
import productsManager from "../../apis/products";
import messageBox from "../../components/MessageBox";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      bundles: [],
      totalBundles: 0,
      loadingBundles: false,

      perPage: staticData.defaultPerPage,
      bundlesOptions: {
        sortBy: ["source_name"],
        sortDesc: [false],
        itemsPerPage: staticData.defaultPerPage,
      },

      bundlesHeaders: [],
      fetchingBundles: false,
    };
  },

  components: {
    messageBox,
    BundleEditor,
  },

  mounted() {
    this.bundlesHeaders = [
      {
        text: "Nome pacchetto",
        value: "source_name",
      },
      {
        text: "Descrizione",
        value: "description",
      },
      {
        text: "Numero prodotti",
        value: "products_count",
      },
      {
        text: "Pagamenti richiesti",
        value: "required_payments",
      },
      {
        text: "Periodo di riferimento",
        value: "reference_dates",
      },

      { text: "", align: "center", sortable: false, value: "actions" },
    ];
  },

  watch: {
    bundlesOptions: {
      handler() {
        clearTimeout(this._bundlesDebounceTimer);
        this._bundlesDebounceTimer = setTimeout(() => {
          this.reloadBundles(true);
        }, 300);
      },
      deep: true,
    },
  },

  methods: {
    addBundle() {
      let bundle = {
        source_name: "Nuovo pacchetto",
        description: "Descrizione",
        required_payments: 0,
        start_date: new Date().toISOString(),
        end_date: new Date().toISOString(),
      };
      this.$refs.bundleEditor
        .show(bundle, false)
        .then((bundle) => {
          productsManager
            .addBundle(bundle)
            .then(() => {
              this.reloadBundles(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editBundle(item) {
      let bundle = Object.assign({}, item);
      this.$refs.bundleEditor
        .show(bundle, true)
        .then((bundle) => {
          productsManager
            .editBundle(bundle)
            .then(() => {
              this.reloadBundles(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    deleteBundle(id) {
      this.$refs.msgBox
        .show(
          "Vuoi davvero confermare la cancellazione del pacchetto ?",
          "Conferma operazione"
        )
        .then(() => {
          productsManager
            .removeBundle(id)
            .then(() => {
              this.reloadBundles(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    reloadBundles: function(showLoader) {
      this.fetchBundles(showLoader)
        .then((data) => {
          this.bundles = data.items;
          this.totalBundles = data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchBundles(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.bundlesOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        productsManager
          .fetchBundles(ctx)
          .then((result) => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch((err) => {
            if (showLoader) this.loading = false;
            reject(err);
          });
      });
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('MessageBox',{ref:"msgBox"}),_c('SetString',{ref:"createFolder"}),_c('FileUploader',{ref:"fileUploader",on:{"uploadCompleted":_vm.handleUploadCompleted},model:{value:(_vm.fileUploader),callback:function ($$v) {_vm.fileUploader=$$v},expression:"fileUploader"}}),_c('div',{staticClass:"elevation-1"},[_c('div',{ref:"crumb_bar",staticClass:"ml-2 text-left body-1 crumb-bar"},_vm._l((_vm.crumbs),function(child,index){return _c('span',{key:index,staticClass:"crumb",attrs:{"item":child},on:{"click":function($event){return _vm.onClickCrumb(_vm.crumbs[index])}}},[_vm._v(_vm._s(child.root ? "Storage" : child.name)+" > ")])}),0),(!_vm.isMobile)?_c('splitpanes',{staticClass:"default-theme",staticStyle:{"min-height":"600px"}},[_c('pane',{attrs:{"size":"20","min-size":"20"}},[_c('div',{staticClass:"text-left file-toolbar"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon",staticStyle:{"text-align":"center"},attrs:{"dark":"","icon":"","disabled":!_vm.isFolderSelected() || !_vm.$store.state.hasRight('40'),"href":"#"},on:{"click":_vm.onCreateFolder}},on),[_c('v-icon',{attrs:{"size":"28","color":"yellow darken-3"}},[_vm._v("mdi-folder-plus")])],1)]}}],null,false,791735102)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-addfolder")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon",attrs:{"dark":"","icon":"","disabled":!_vm.isFolderSelected() || _vm.isRootItem() || !_vm.$store.state.hasRight('41'),"href":"#"},on:{"click":_vm.onRemoveFolder}},on),[_c('v-icon',{attrs:{"size":"28","color":"#f03f3b"}},[_vm._v("mdi-folder-remove")])],1)]}}],null,false,1393932774)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-remove-folder")))])])],1),_c('div',[_c('div',{staticClass:"folders_scroll_area"},[_c('FoldersItem',{ref:"root_folder",attrs:{"folderOnly":true,"disabled":_vm.isFetching,"fetchCallback":_vm.fetchCallback,"selectCallback":_vm.selectCallback,"item":_vm.treeData,"indent":1}})],1)])]),_c('pane',{attrs:{"min-size":"20"}},[_c('div',{staticClass:"text-left file-toolbar"},[(_vm.selectionMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon",staticStyle:{"text-align":"center"},attrs:{"dark":"","icon":"","disabled":!_vm.isFileSelected(),"href":"#","color":"success"},on:{"click":_vm.onSelectFile}},on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v("mdi-check-circle-outline")])],1)]}}],null,false,1337858459)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-select")))])]):_vm._e(),(!_vm.selectionMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon",staticStyle:{"text-align":"center"},attrs:{"dark":"","icon":"","download":_vm.downloadFileHint,"target":"_blank","href":_vm.downloadFilePath,"disabled":!_vm.isFileSelected() || !_vm.$store.state.hasRight('39'),"color":"success"}},on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v("mdi-download")])],1)]}}],null,false,873546248)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-download")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon",staticStyle:{"text-align":"center"},attrs:{"dark":"","icon":"","disabled":!_vm.isFolderSelected() || !_vm.$store.state.hasRight('37'),"href":"#","color":"orange"},on:{"click":_vm.onUploadFile}},on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v("mdi-upload")])],1)]}}],null,false,970873078)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-upload")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon",attrs:{"dark":"","icon":"","disabled":!_vm.isFileSelected() || !_vm.$store.state.hasRight('38'),"href":"#","color":"#f03f3b"},on:{"click":_vm.onRemoveFile}},on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,false,3128148822)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-remove-file")))])])],1),_c('div',{staticClass:"file-manager-header"},[_c('v-row',{staticClass:"file-header",attrs:{"dense":""}},[_c('v-col',{staticClass:"text-left pl-4",attrs:{"cols":"7"}},[_vm._v(_vm._s(_vm.$t("fileview-tbl-name")))]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_vm._v(_vm._s(_vm.$t("fileview-tbl-modified")))]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.$t("fileview-tbl-size")))])],1),(this.selectedFolder)?_c('div',{key:this.filesViewKey,staticClass:"files_scroll_area"},_vm._l((this.selectedFolder.item.files),function(child,index){return _c('FileItem',{key:index,attrs:{"item":child},on:{"itemSelected":function($event){return _vm.fileSelected($event)}}})}),1):_vm._e()],1)])],1):_vm._e(),(_vm.isMobile)?_c('div',{staticStyle:{"min-height":"600px"}},[_c('div',{staticClass:"text-left file-toolbar-mobile"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon-mobile",staticStyle:{"text-align":"center"},attrs:{"dark":"","icon":"","disabled":!_vm.isFolderSelected(),"href":"#"},on:{"click":_vm.onCreateFolder}},on),[_c('v-icon',{attrs:{"color":"yellow darken-3"}},[_vm._v("mdi-folder-plus")])],1)]}}],null,false,3118266904)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-addfolder")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon-mobile",attrs:{"dark":"","icon":"","disabled":!_vm.isFolderSelected() || _vm.isRootItem(),"href":"#"},on:{"click":_vm.onRemoveFolder}},on),[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-folder-remove")])],1)]}}],null,false,2761485914)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-remove-folder")))])]),(_vm.selectionMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon-mobile",staticStyle:{"text-align":"center"},attrs:{"dark":"","icon":"","disabled":!_vm.isFileSelected(),"href":"#","color":"success"},on:{"click":_vm.onSelectFile}},on),[_c('v-icon',[_vm._v("mdi-check-circle-outline")])],1)]}}],null,false,3452999989)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-select")))])]):_vm._e(),(!_vm.selectionMode)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon-mobile",staticStyle:{"text-align":"center"},attrs:{"dark":"","icon":"","download":_vm.downloadFileHint,"href":_vm.downloadFilePath,"disabled":!_vm.isFileSelected(),"color":"accent"}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,false,3527154837)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-download")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon-mobile",staticStyle:{"text-align":"center"},attrs:{"dark":"","icon":"","disabled":!_vm.isFolderSelected(),"href":"#","color":"accent"},on:{"click":_vm.onUploadFile}},on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}],null,false,609335140)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-upload")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar-icon-mobile",attrs:{"dark":"","icon":"","disabled":!_vm.isFileSelected(),"href":"#","color":"warning"},on:{"click":_vm.onRemoveFile}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,false,1372999806)},[_c('span',[_vm._v(_vm._s(_vm.$t("fileview-remove-file")))])])],1),_c('div',{staticClass:"folders_scroll_area"},[_c('FoldersItem',{ref:"root_folder",attrs:{"fileSelectCallback":_vm.folderFileSelectCallback,"folderOnly":false,"disabled":_vm.isFetching,"fetchCallback":_vm.fetchCallback,"selectCallback":_vm.selectCallback,"item":_vm.treeData,"indent":1}})],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
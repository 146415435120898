<template>
  <div>
    <messageBox ref="msgBox" />
    <ProductEditor :categories="categories" ref="productEditor" />
    <categoryEditor
      @categoryEditingDone="handleCategoryEditingDone"
      ref="categoryEditor"
      v-model="categoryEditorDialog"
      :categories="categories"
    />
    <macroCategoryEditor
      @macrocategoryEditingDone="handleMacroCategoryEditingDone"
      ref="macroCategoryEditor"
      v-model="macroCategoryEditorDialog"
      :macroCategories="macroCategories"
    />
    <supplierEditor
      @supplierEditingDone="handleSupplierEditingDone"
      ref="supplierEditor"
      v-model="supplierEditorDialog"
      :suppliers="suppliers"
    />
    <FileUploader
      ref="fileUploader"
      @uploadCompleted="handleUploadCompleted"
      v-model="fileUploader"
    />
    <v-dialog v-model="product_attachments" width="600">
      <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-0"
                      ><v-icon large>mdi-attachments</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="10"
                      class="pa-0 card-title-text"
                      style="font-size: 25px"
                      >{{ "Allegati prodotto" }}</v-col
                    >
                    <v-col cols="1" class="pa-0 text-right"
                      ><v-icon @click="product_attachments = false" large
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </template>
                <v-card-text
                  ><v-row v-if="loadingAttachments"
                    ><v-col>
                      <v-progress-circular
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="primary"
                      ></v-progress-circular> </v-col
                  ></v-row>

                  <v-row class="text-center" v-if="!loadingAttachments">
                    <v-col>
                      <v-btn
                        width="120"
                        @click="loadProductFile()"
                        class="ma-2"
                        color="primary"
                        >Carica file</v-btn
                      ></v-col
                    >
                  </v-row>
                  <v-row v-if="!loadingAttachments" dense>
                    <v-col>
                      <v-data-table
                        :headers="attachmentHeaders"
                        :items="product_attachments_items"
                      >
                        <template v-slot:item.path="{ item }">
                          {{ basename(item.path) }}
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <div class="text-right">
                            <v-icon
                              small
                              color="green"
                              v-if="true"
                              @click="downloadItem(item)"
                              >mdi-arrow-down</v-icon
                            >
                            <v-icon
                              small
                              color="error"
                              v-if="true"
                              @click="deleteItem(item)"
                              >mdi-delete</v-icon
                            >
                          </div>
                        </template>
                      </v-data-table>
                    </v-col></v-row
                  >

                  <v-row class="text-center">
                    <v-col>
                      <v-btn
                        disabled
                        width="120"
                        @click="product_attachments = false"
                        class="ma-2"
                        color="secondary"
                        >Chiudi</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </ValidationObserver>
    </v-dialog>

    <v-dialog v-model="warehouse_load_unload" width="600">
      <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
        <v-container fill-height fluid grid-list-xl>
          <v-layout justify-center wrap>
            <v-flex md12>
              <base-material-card class="pa-0" color="primary">
                <template v-slot:heading>
                  <v-row class="pa-0">
                    <v-col cols="1" class="pa-0"
                      ><v-icon large>mdi-archive-outline</v-icon></v-col
                    >
                    <v-col
                      align="center"
                      cols="10"
                      class="pa-0 card-title-text"
                      style="font-size: 25px"
                      >{{
                        loadWarehouse ? "Carico magazzino" : "Scarico magazzino"
                      }}</v-col
                    >
                    <v-col cols="1" class="pa-0 text-right"
                      ><v-icon @click="warehouse_load_unload = false" large
                        >mdi-close</v-icon
                      ></v-col
                    >
                  </v-row>
                </template>
                <v-card-text>
                  <v-row dense>
                    <v-col>
                      <ValidationProvider
                        rules="required|numeric|min_value:1"
                        v-slot="{ errors, valid }"
                        :immediate="true"
                      >
                        <v-text-field
                          v-model="warehouse_amount"
                          outlined
                          dense
                          label="Quantità"
                          :error-messages="errors"
                          :success="valid"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors, valid }"
                        :immediate="true"
                      >
                        <v-select
                          v-model="warehouse_location"
                          :items="warehouses"
                          outlined
                          dense
                          label="Magazzino"
                          :error-messages="errors"
                          :success="valid"
                          item-value="id"
                          item-text="name"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <ValidationProvider
                        rules="required|numeric"
                        v-slot="{ errors, valid }"
                        :immediate="true"
                      >
                        <v-text-field
                          v-model="warehouse_price"
                          outlined
                          dense
                          label="Importo operazione"
                          :error-messages="errors"
                          :success="valid"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row dense class="text-center">
                    <v-col>
                      <v-file-input
                        :disabled="warehouseDocumentUploadInProgress"
                        v-model="warehouseDocumentFile"
                        outlined
                        dense
                        accept="*"
                        label="Documento operazione"
                      ></v-file-input
                    ></v-col>
                  </v-row>
                  <v-row dense v-if="warehouseDocumentUploadInProgress"
                    ><v-col cols="11"
                      ><v-progress-linear
                        striped
                        style="height:24px"
                        v-model="warehouseDocumentUploadProgressPerc"
                    /></v-col>
                    <v-col cols="1">
                      <v-progress-circular
                        v-if="warehouseDocumentS3SyncInProgress"
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="primary"
                      ></v-progress-circular> </v-col
                  ></v-row>

                  <v-row class="text-center">
                    <v-col>
                      <v-btn
                        :disabled="
                          invalid ||
                            !validated ||
                            warehouseDocumentUploadInProgress
                        "
                        width="120"
                        style="color:white;"
                        @click="confirmWarehouse()"
                        class="ma-2"
                        color="primary"
                        >Conferma</v-btn
                      >
                      <v-btn
                        :disabled="warehouseDocumentUploadInProgress"
                        width="120"
                        @click="cancelWarehouse()"
                        class="ma-2"
                        color="secondary"
                        >Annulla</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </base-material-card>
            </v-flex>
          </v-layout>
        </v-container>
      </ValidationObserver>
    </v-dialog>

    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col cols="1" class="pa-0"
            ><v-icon large>mdi-bottle-wine</v-icon></v-col
          >
          <v-col
            align="center"
            cols="11"
            class="pa-0 card-title-text"
            style="font-size: 20px"
            >Gestione prodotti</v-col
          >
        </v-row>
      </template>

      <v-card-text>
        <v-row v-if="loadingData"
          ><v-col>
            <v-progress-circular
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular> </v-col
        ></v-row>

        <div style="height:20px" />
        <v-row
          v-if="!loadingData"
          class="text-center"
          align="center"
          justify="center"
          ><v-btn
            v-if="$store.state.hasRight('275')"
            v-on:click="addProduct()"
            :disabled="loadingProducts"
            color="primary"
            dark
            >Aggiungi prodotto</v-btn
          >
          <v-btn
            v-if="$store.state.hasRight('275')"
            v-on:click="manageCategory()"
            :disabled="loadingProducts"
            color="primary"
            >{{ "Gestione categorie" }}</v-btn
          >
          <v-btn
            v-if="$store.state.hasRight('275')"
            v-on:click="manageMacroCategory()"
            :disabled="loadingProducts"
            color="primary"
            >{{ "Gestione macrocategorie" }}</v-btn
          >
          <v-btn
            v-if="$store.state.hasRight('275')"
            v-on:click="manageSupplier()"
            :disabled="loadingProducts"
            color="primary"
            >{{ "Gestione fornitori" }}</v-btn
          >
        </v-row>
        <v-row v-if="!loadingData">
          <v-col cols="8">
            <v-text-field
              outlined
              dense
              v-model="filter"
              append-icon="mdi-magnify"
              class="mr-auto ml-1 pl-1"
              label="Ricerca"
              hide-details
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="categoryFiter"
              label="Categoria"
              required
              outlined
              dense
              :items="categoriesFilters"
              item-value="id"
              item-text="name"
            ></v-select>
          </v-col>
        </v-row>
        <v-data-table
          v-if="!loadingData"
          :items-per-page="perPage"
          :must-sort="true"
          :headers="productsHeaders"
          :items="products"
          :options.sync="productsOptions"
          :server-items-length="totalProducts"
          :loading="loadingProducts"
          :footer-props="dataTableFooterOptions"
          item-key="id"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('276')"
                  x-small
                  text
                  icon
                  color="secondary"
                  @click.stop="editProduct(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>Modifica vino</span></v-tooltip
            >

            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('277')"
                  x-small
                  text
                  icon
                  color="error"
                  @click.stop="deleteProduct(item.id)"
                  v-on="on"
                  ><v-icon>mdi-delete</v-icon></v-btn
                ></template
              ><span>Rimuovi vino</span></v-tooltip
            >
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('288')"
                  x-small
                  text
                  icon
                  color="green"
                  @click.stop="uploadWarehouse(item)"
                  v-on="on"
                  ><v-icon>mdi-arrow-up</v-icon></v-btn
                ></template
              ><span>Carica magazzino</span></v-tooltip
            >
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('289')"
                  x-small
                  text
                  icon
                  color="primary"
                  @click.stop="downloadWarehouse(item)"
                  v-on="on"
                  ><v-icon>mdi-arrow-down</v-icon></v-btn
                ></template
              ><span>Scarica magazzino</span></v-tooltip
            >
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('290')"
                  x-small
                  text
                  icon
                  color="primary"
                  @click.stop="manageAttachments(item)"
                  v-on="on"
                  ><v-icon>mdi-attachment</v-icon></v-btn
                ></template
              ><span>Gestione allegati</span></v-tooltip
            >
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
  </div>
</template>

<script>
import ProductEditor from "../../components/ProductEditor";
import CategoryEditor from "../../components/CategoryEditor";
import MacroCategoryEditor from "../../components/MacrocategoryEditor";
import SupplierEditor from "../../components/SupplierEditor";
import staticData from "../../apis/staticData";
import productsManager from "../../apis/products";
import messageBox from "../../components/MessageBox";
import FileUploader from "../../components/FileUploader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      fileUploader: false,
      products: [],
      totalProducts: 0,
      filter: null,
      categoryFiter: null,
      loadingProducts: false,
      loadingData: false,
      perPage: staticData.defaultPerPage,
      productsOptions: {
        sortBy: ["name"],
        sortDesc: [false],
        itemsPerPage: staticData.defaultPerPage
      },

      productsHeaders: [],
      fetchingProducts: false,
      categoryEditorDialog: false,
      macroCategoryEditorDialog : false,
      supplierEditorDialog : false,
      categories: [],
      suppliers : [],
      macroCategories : [],
      categoriesFilters: [],
      warehouse_load_unload: false,
      warehouse_amount: 1,
      loadWarehouse: false,
      warehouse_location: null,
      warehouse_price: 0,
      warehouse_product_id: null,
      product_attachments: false,
      loadingAttachments: false,
      product_attachment_product: null,
      product_attachments_items: [],

      warehouseDocumentFile: null,
      warehouseDocumentFileMax: 100000000,
      warehouseDocumentFileSize: 0,
      warehouseDocumentUploadProgressPerc: 0,
      warehouseDocumentFilePath: "",
      warehouseDocumentUploadInProgress: false,
      warehouseDocumentFileS3SyncInProgress: false
    };
  },

  components: {
    messageBox,
    ProductEditor,
    CategoryEditor,
    MacroCategoryEditor,
    SupplierEditor,
    ValidationObserver,
    ValidationProvider,
    FileUploader
  },

  mounted() {
    this.loadingData = true;
    this.fetchWarehouses().then(() => {
      this.attachmentHeaders = [
        {
          text: "File",
          value: "path",
          sortable: false
        },

        {
          value: "actions",
          sortable: false
        }
      ];
      this.productsHeaders = [
        {
          text: "Nome vino",
          value: "name"
        },
        {
          text: "Cantina",
          value: "structure"
        },
        {
          text: "Media",
          value: "media_count",
          sortable: false,
          align: "center"
        }
      ];

      for (let x of this.warehouses) {
        this.productsHeaders.push({
          text: x.name,
          align: "center",
          sortable: false,
          value: "warehouse_" + x.id
        });
      }
      this.productsHeaders.push({
        text: "",
        align: "center",
        sortable: false,
        value: "actions"
      });
      this.fetchCategories().then(() => {
        this.loadingData = false;
      });
    });
  },

  watch: {
    productsOptions: {
      handler() {
        clearTimeout(this._productsDebounceTimer);
        this._productsDebounceTimer = setTimeout(() => {
          this.reloadProducts(true);
        }, 300);
      },
      deep: true
    },

    filter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadProducts(true);
        }, 300);
      }
    },

    categoryFiter: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadProducts(true);
        }, 300);
      }
    }
  },

  methods: {
    deleteItem(item) {
      this.$refs.msgBox
        .show(
          this.$t("msgbox-confirm-op"),
          this.$t("usrmng-msgbox-confirm-delete")
        )
        .then(() => {
          productsManager
            .removeAttachment(this.product_attachment_product.id, item.id)
            .then(() => {
              this.fetchProductAttachments();
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    downloadItem(item) {
      productsManager.downloadFile(item.path);
    },

    basename: function(path) {
      return path.split("/").reverse()[0];
    },

    handleUploadCompleted: function(payload) {
      productsManager.addAttachment(payload.item.id, payload.path).then(() => {
        this.fetchProductAttachments();
      });
    },

    loadProductFile: function() {
      let file_path =
        "Documents/Prodotti/" +
        this.$store.state.backend +
        "/" +
        this.product_attachment_product.id +
        "/";
      this.$refs.fileUploader.setPath(
        this.product_attachment_product,
        file_path
      );
      this.fileUploader = true;
    },

    manageAttachments(item) {
      this.product_attachments = true;
      this.product_attachment_product = item;
      this.fetchProductAttachments();
    },

    fetchProductAttachments() {
      this.loadingAttachments = true;
      productsManager
        .fetchProductAttachments(this.product_attachment_product.id)
        .then(res => {
          this.product_attachments_items = res;
          this.loadingAttachments = false;
        })
        .catch(err => {
          console.log(err);
          this.loadingAttachments = false;
        });
    },

    uploadWarehouse(item) {
      this.loadWarehouse = true;
      this.warehouseDocumentUploadInProgress = false;
      this.warehouseDocumentFile = null;
      this.warehouseDocumentS3SyncInProgress = false;
      this.warehouse_amount = 1;
      this.warehouse_load_unload = true;
      this.warehouse_product_id = item.id;
      this.warehouse_location = this.warehouses[0].id;
      this.warehouse_price = 0;
    },

    downloadWarehouse(item) {
      this.loadWarehouse = false;
      this.warehouseDocumentUploadInProgress = false;
      this.warehouseDocumentFile = null;
      this.warehouseDocumentS3SyncInProgress = false;
      this.warehouse_amount = 1;
      this.warehouse_load_unload = true;
      this.warehouse_product_id = item.id;
      this.warehouse_location = this.warehouses[0].id;
      this.warehouse_price = 0;
    },

    cancelWarehouse() {
      this.warehouse_load_unload = false;
    },

    fileUploadProgress(result) {
      this.warehouseDocumentFileMax = result.total;
      this.warehouseDocumentFileSize = result.loaded;
      this.warehouseDocumentUploadProgressPerc =
        (this.warehouseDocumentFileSize / this.warehouseDocumentFileMax) *
        100.0;
      if (this.warehouseDocumentFileSize == this.warehouseDocumentFileMax)
        this.warehouseDocumentS3SyncInProgress = true;
    },

    confirmWarehouse() {
      let formData = new FormData();
      formData.append("file", this.warehouseDocumentFile);
      if (this.warehouseDocumentFile)
        this.warehouseDocumentUploadInProgress = true;
      if (this.loadWarehouse) {
        productsManager
          .loadWarehouse(
            this.fileUploadProgress,
            this.warehouse_amount,
            this.warehouse_location,
            this.warehouse_product_id,
            this.warehouse_price,
            formData
          )
          .then(() => {
            this.warehouse_load_unload = false;
            this.reloadProducts(false);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        productsManager
          .unloadWarehouse(
            this.fileUploadProgress,
            this.warehouse_amount,
            this.warehouse_location,
            this.warehouse_product_id,
            this.warehouse_price,
            formData
          )
          .then(() => {
            this.warehouse_load_unload = false;
            this.reloadProducts(false);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    manageCategory() {
      this.categoryEditorDialog = true;
    },

    manageMacroCategory() {
      this.macroCategoryEditorDialog = true;
    },

    manageSupplier() {
      this.supplierEditorDialog = true;
    },

    addProduct() {
      let product = {
        name: "Nome prodotto",
        structure: "Struttura"
      };
      this.$refs.productEditor
        .show(product, false)
        .then(product => {
          productsManager
            .addProduct(product)
            .then(() => {
              this.reloadProducts(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editProduct(item) {
      let product = Object.assign({}, item);
      this.$refs.productEditor
        .show(product, true)
        .then(product => {
          productsManager
            .editProduct(product)
            .then(() => {
              this.reloadProducts(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    deleteProduct(id) {
      this.$refs.msgBox
        .show(
          "Vuoi davvero confermare la cancellazione del prodotto ?",
          "Conferma operazione"
        )
        .then(() => {
          productsManager
            .removeProduct(id)
            .then(() => {
              this.reloadProducts(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    reloadProducts: function(showLoader) {
      this.fetchProducts(showLoader)
        .then(data => {
          this.products = data.items;
          this.totalProducts = data.totalCount;
        })
        .catch(err => {
          console.log(err);
        });
    },

    handleCategoryEditingDone() {
      this.fetchCategories();
    },

    fetchWarehouses() {
      return new Promise((resolve, reject) => {
        productsManager
          .fetchWarehouses()
          .then(result => {
            this.warehouses = result.warehouses;
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    fetchCategories(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.productsOptions);

        productsManager
          .fetchCategories(ctx)
          .then(result => {
            this.categories = result.items;
            this.categoriesFilters = [
              { id: null, name: "Tutte le categorie" },
              ...this.categories
            ];
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loading = false;
            reject(err);
          });
      });
    },

    fetchProducts(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.productsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.categoryFiter = this.categoryFiter;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        productsManager
          .fetchProducts(ctx)
          .then(result => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loading = false;
            reject(err);
          });
      });
    }
  }
};
</script>

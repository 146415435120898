<template>
  <OneTimeOrders location="it"> </OneTimeOrders>
</template>

<script>
import OneTimeOrders from "../../components/OneTimeOrders";

export default {
  components: {
    OneTimeOrders
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay-opacity":"0.6","max-width":"800","value":_vm.openDialog}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-bottle-wine")])],1),_c('v-col',{staticStyle:{"font-size":"20px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.editing ? "Modifica prodotto" : "Aggiungi prodotto"))]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)],1)]},proxy:true}])},[_c('v-card-text',[_c('v-form',{ref:"user-editor-form",staticClass:"mt-5"},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Nome","prepend-icon":"mdi-bottle-wine","error-messages":errors,"success":valid,"required":"","outlined":"","dense":""},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"label":"Categoria","error-messages":errors,"success":valid,"prepend-icon":"mdi-group","required":"","outlined":"","dense":"","items":_vm.categories,"item-value":"id","item-text":"name"},model:{value:(_vm.product.category_id),callback:function ($$v) {_vm.$set(_vm.product, "category_id", $$v)},expression:"product.category_id"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Struttura","prepend-icon":"mdi-home-variant","outlined":"","dense":""},model:{value:(_vm.product.structure),callback:function ($$v) {_vm.$set(_vm.product, "structure", $$v)},expression:"product.structure"}}),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('v-btn',{staticClass:"ma-2",staticStyle:{"color":"white"},attrs:{"disabled":invalid || !validated,"width":"120","color":"primary"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v("Conferma")]),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","width":"120","color":"secondary"},on:{"click":function($event){return _vm.dismiss()}}},[_vm._v("Annulla")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
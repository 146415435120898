<template>
  <v-dialog
    persistent
    overlay-opacity="0.6"
    max-width="800"
    :value="openDialog"
  >
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1"><v-icon large>mdi-bottle-wine</v-icon></v-col>
                <v-col align="center" style="font-size: 20px" cols="10">{{
                  editing ? "Modifica prodotto" : "Aggiungi prodotto"
                }}</v-col>
                <v-col cols="1">
                  <v-btn icon>
                    <v-icon @click="closeDialog" large>mdi-close</v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </template>

            <v-card-text>
              <v-form class="mt-5" ref="user-editor-form">
                <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
                  <ValidationProvider
                    :immediate="true"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      v-model="product.name"
                      label="Nome"
                      prepend-icon="mdi-bottle-wine"
                      :error-messages="errors"
                      :success="valid"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                    :immediate="true"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      v-model="product.category_id"
                      label="Categoria"
                      :error-messages="errors"
                      :success="valid"
                      prepend-icon="mdi-group"
                      required
                      outlined
                      dense
                      :items="categories"
                      item-value="id"
                      item-text="name"
                    ></v-select>
                  </ValidationProvider>
                  <v-text-field
                    v-model="product.structure"
                    label="Struttura"
                    prepend-icon="mdi-home-variant"
                    outlined
                    dense
                  ></v-text-field>
                

                  <v-row class="text-center">
                    <v-col>
                      <v-btn
                        :disabled="invalid || !validated"
                        width="120"
                        style="color:white;"
                        @click="confirm()"
                        class="ma-2"
                        color="primary"
                        >Conferma</v-btn
                      >
                      <v-btn
                        dark
                        width="120"
                        @click="dismiss()"
                        class="ma-2"
                        color="secondary"
                        >Annulla</v-btn
                      >
                    </v-col>
                  </v-row>
                </ValidationObserver>
              </v-form>
            </v-card-text>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      product: null,
      openDialog: false,
      dialogResolve: null,
      dialogReject: null,
      editing: false
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: ["categories"],

  methods: {
    show(product, editing) {
      return new Promise((resolve, reject) => {
        this.product = product;
        this.editing = editing;
        this.dialogResolve = resolve;
        this.dialogReject = reject;
        this.openDialog = true;
      });
    },

    hide() {
      this.openDialog = false;
      this.dialogReject();
    },

    confirm() {
      this.openDialog = false;
      this.dialogResolve(this.product);
    },

    dismiss() {
      this.hide();
    },

    closeDialog() {
      this.hide();
    }
  }
};
</script>

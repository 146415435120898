var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('messageBox',{ref:"msgBox"}),_c('setString',{ref:"setDescription"}),_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-database")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"20px"},attrs:{"align":"center","cols":"11"}},[_vm._v("Gestione esportazioni")])],1)]},proxy:true}])},[_c('v-card-text',[_c('div',{staticStyle:{"margin":"15px"}},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"disabled":_vm.fetchingFilters,"dense":"","outlined":"","item-text":"name","item-value":"id","items":_vm.shipments,"label":"Spedizione","hide-details":""},model:{value:(_vm.filters.filterByShipmentId),callback:function ($$v) {_vm.$set(_vm.filters, "filterByShipmentId", $$v)},expression:"filters.filterByShipmentId"}})],1),_c('v-col',[_c('v-select',{ref:"period",staticClass:"minfield",attrs:{"disabled":_vm.fetchingFilters,"outlined":"","dense":"","label":"Periodo","items":_vm.getPeriodFilters,"item-text":"name","item-value":"id"},model:{value:(_vm.filters.filterByPeriod),callback:function ($$v) {_vm.$set(_vm.filters, "filterByPeriod", $$v)},expression:"filters.filterByPeriod"}})],1),(_vm.filters.filterByPeriod == 13)?_c('v-col',[_c('v-dialog',{ref:"dialog_start_date",attrs:{"return-value":_vm.filters.filterByCustomDateStart,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "filterByCustomDateStart", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "filterByCustomDateStart", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"disabled":_vm.fetchingFilters,"dense":"","outlined":"","label":"Data iniziale","readonly":"","clearable":""},model:{value:(_vm.start_date_formatted),callback:function ($$v) {_vm.start_date_formatted=$$v},expression:"start_date_formatted"}},on))]}}],null,false,2635940383),model:{value:(_vm.date_start_modal),callback:function ($$v) {_vm.date_start_modal=$$v},expression:"date_start_modal"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":1,"locale":_vm.$store.state.user.locale},on:{"input":function($event){return _vm.$refs.dialog_start_date.save(
                    _vm.filters.filterByCustomDateStart
                  )}},model:{value:(_vm.filters.filterByCustomDateStart),callback:function ($$v) {_vm.$set(_vm.filters, "filterByCustomDateStart", $$v)},expression:"filters.filterByCustomDateStart"}})],1)],1):_vm._e(),(_vm.filters.filterByPeriod == 13)?_c('v-col',[_c('v-dialog',{ref:"dialog_end_date",attrs:{"return-value":_vm.filters.filterByCustomDateEnd,"width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "filterByCustomDateEnd", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "filterByCustomDateEnd", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"disabled":_vm.fetchingFilters,"dense":"","outlined":"","label":"Data finale","readonly":"","clearable":""},model:{value:(_vm.end_date_formatted),callback:function ($$v) {_vm.end_date_formatted=$$v},expression:"end_date_formatted"}},on))]}}],null,false,3682692204),model:{value:(_vm.date_end_modal),callback:function ($$v) {_vm.date_end_modal=$$v},expression:"date_end_modal"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":1,"locale":_vm.$store.state.user.locale},on:{"input":function($event){return _vm.$refs.dialog_end_date.save(_vm.filters.filterByCustomDateEnd)}},model:{value:(_vm.filters.filterByCustomDateEnd),callback:function ($$v) {_vm.$set(_vm.filters, "filterByCustomDateEnd", $$v)},expression:"filters.filterByCustomDateEnd"}})],1)],1):_vm._e()],1)],1),(!_vm.fetchingFilters)?_c('v-data-table',{attrs:{"items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.exports,"options":_vm.exportsOptions,"server-items-length":_vm.totalExports,"loading":_vm.loadingExports,"footer-props":_vm.dataTableFooterOptions,"item-key":"id"},on:{"update:options":function($event){_vm.exportsOptions=$event}},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.$store.state.isAdmin())?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.setDescription(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modifica descrizione")])]),_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.date",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTime")(item.date))+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.$store.state.hasRight('269'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.exportMos(item)}}},on),[_c('v-icon',[_vm._v("mdi-truck-delivery")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Esporta ZIP MOS")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.$store.state.hasRight('269'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.exportInvoices(item)}}},on),[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Esporta Excel Fatture")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.$store.state.hasRight('270'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.exportCsv(item)}}},on),[_c('v-icon',[_vm._v("mdi-file")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Esporta CSV")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.$store.state.hasRight('271'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.exportExcel(item)}}},on),[_c('v-icon',[_vm._v("mdi-file-excel")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Esporta Excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.$store.state.hasRight('272'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.exportCourierExcel(item)}}},on),[_c('v-icon',[_vm._v("mdi-file-excel")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Esporta Excel Spedizioniere")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(_vm.$store.state.hasRight('273'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteExport(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Elimina esportazione")])])]}}],null,false,1778722677)}):_vm._e()],1)],1),_c('div',{staticStyle:{"height":"20px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
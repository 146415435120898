<template>
  <div>
    <messageBox ref="msgBox" />
    <setString ref="setDescription" />
    <ticketCreator ref="ticketCreator" />
    <ticketDetail ref="ticketDetail" />
    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row justify="center" class="pa-0">
          <v-col cols="1" class="pa-0"><v-icon large>mdi-truck</v-icon></v-col>
          <v-col
            align="center"
            cols="9"
            class="pa-0 card-title-text"
            style="margin-top:10px; font-size: 20px"
            >Dettaglio esportazioni</v-col
          >
          <v-col align="right"
            >Tickets aperti:
            <v-chip class="mr-1" color="red" small>{{ errorTickets }}</v-chip
            ><v-chip class="mr-1" color="orange" small>{{
              warningTickets
            }}</v-chip
            ><v-chip color="green" small>{{ noticeTickets }}</v-chip></v-col
          >
        </v-row>
      </template>

      <v-card-text>
        <div style="margin:15px">
          <v-row>
            <v-col>
              <v-text-field
                outlined
                v-model="filters.filter"
                append-icon="mdi-magnify"
                class="mr-auto ml-1 pl-1"
                label="Ricerca per tracking, ordine o email"
                hide-details
                dense
              />
            </v-col>

            <v-col>
              <v-select
                :disabled="fetchingFilters"
                dense
                v-model="filters.filterByShipmentId"
                outlined
                item-text="name"
                item-value="id"
                :items="shipments"
                label="Spedizione"
                hide-details
            /></v-col>
            <v-col>
              <v-select
                :disabled="fetchingFilters"
                class="minfield"
                outlined
                dense
                label="Stato tracking"
                v-model="filters.filterByTrackStatus"
                :items="trackingStatus"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                :disabled="fetchingFilters"
                class="minfield"
                outlined
                dense
                label="Tickets"
                v-model="filters.filterByTickets"
                :items="ticketsFilters"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                :disabled="fetchingFilters"
                class="minfield"
                outlined
                dense
                label="Periodo"
                ref="period"
                v-model="filters.filterByPeriod"
                :items="getPeriodFilters"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>

            <v-col v-if="filters.filterByPeriod == 13">
              <v-dialog
                ref="dialog_start_date"
                v-model="date_start_modal"
                :return-value.sync="filters.filterByCustomDateStart"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="fetchingFilters"
                    dense
                    outlined
                    v-model="start_date_formatted"
                    label="Data iniziale"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="
                    $refs.dialog_start_date.save(
                      filters.filterByCustomDateStart
                    )
                  "
                  v-model="filters.filterByCustomDateStart"
                  scrollable
                  :first-day-of-week="1"
                  :locale="$store.state.user.locale"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col v-if="filters.filterByPeriod == 13">
              <v-dialog
                ref="dialog_end_date"
                v-model="date_end_modal"
                :return-value.sync="filters.filterByCustomDateEnd"
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="fetchingFilters"
                    dense
                    outlined
                    v-model="end_date_formatted"
                    label="Data finale"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  @input="
                    $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                  "
                  v-model="filters.filterByCustomDateEnd"
                  scrollable
                  :first-day-of-week="1"
                  :locale="$store.state.user.locale"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </div>

        <v-data-table
          v-if="!fetchingFilters"
          :items-per-page="perPage"
          :must-sort="true"
          :headers="headers"
          :items="exports"
          :options.sync="exportsOptions"
          :server-items-length="totalExports"
          :loading="loadingExports"
          :footer-props="dataTableFooterOptions"
          item-key="id"
        >
          <template v-slot:item.export_date="{ item }">
            {{ item.export_date | toLocaleDateTime }}
          </template>

          <template v-slot:item.custom_tracking_registered="{ item }">
            <v-icon color="green" v-if="item.custom_tracking_registered"
              >mdi-check-circle-outline</v-icon
            >
          </template>

          <template v-slot:item.courier_tracking="{ item }">
            <span v-html="fetchCourierTracking(item)" />
          </template>

          <template v-slot:item.tracking_state="{ item }">
            <span v-html="fetchTrackingState(item)" />
          </template>

          <template v-slot:item.delivered="{ item }">
            <v-icon color="green" v-if="item.delivered"
              >mdi-check-circle-outline</v-icon
            >
          </template>

          <template v-slot:item.tickets="{ item }">
            <div style="min-width:64px">
              <v-tooltip
                v-if="!item.exception_id && $store.state.hasRight('282')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="secondary"
                    @click.stop="openTicket(item)"
                    v-on="on"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  ></template
                ><span>{{ "Apri eccezzione" }}</span></v-tooltip
              >
              <v-tooltip
                v-if="item.exception_id && $store.state.hasRight('283')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    :color="getExceptionColor(item)"
                    @click.stop="showTicket(item)"
                    v-on="on"
                    ><v-icon>mdi-alert</v-icon></v-btn
                  ></template
                ><span>{{ item.exception_title }}</span></v-tooltip
              >
              <v-tooltip
                v-if="item.exception_id && $store.state.hasRight('284')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="secondary"
                    @click.stop="removeTicket(item)"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  ></template
                ><span>{{ "Rimuovi eccezzione" }}</span></v-tooltip
              >
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <div style="height:20px" />
  </div>
</template>

<script>
import staticData from "../../apis/staticData";
import subscriptionsManager from "../../apis/subscriptions";
import messageBox from "../../components/MessageBox";
import setString from "../../components/SetString";
import ticketDetail from "../../components/TicketDetail";
import ticketCreator from "../../components/TicketCreator";
import ticketManager from "../../apis/tickets";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,

      exports: [],
      totalExports: 0,
      shipments: [],
      filter: null,
      loadingExports: false,

      perPage: staticData.defaultPerPage,
      exportsOptions: {
        sortBy: ["date"],
        sortDesc: [true],
        itemsPerPage: staticData.defaultPerPage
      },

      filters: {
        filterByShipmentId: null,
        filterByPeriod: 14,
        filterByTickets: null,
        filterByCustomDateEnd: null,
        filterByCustomDateStart: null,
        filterByTrackStatus: null,
        filter: null
      },
      date_start_modal: false,
      date_end_modal: false,
      date_start: null,
      date_end: null,
      errorTickets: "...",
      warningTickets: "...",
      noticeTickets: "...",
      headers: [],
      fetchingExports: false,
      fetchingFilters: false,
      ticketsFilters: [
        { id: null, name: this.$t("gbl-all-male") },
        { id: 1, name: "Note" },
        { id: 2, name: "Allerta" },
        { id: 3, name: "Gravi" }
      ],
      trackingStatus: [
        { id: null, name: this.$t("gbl-all-female") },
        { id: 1, name: "Acquisito MBE" },
        { id: 2, name: "Acquisito Corriere" },
        { id: 3, name: "Consegnato" }
      ]
    };
  },

  components: {
    messageBox,
    setString,
    ticketCreator,
    ticketDetail
  },

  mounted() {
    this.headers = [
      {
        text: "ID Export",
        value: "id"
      },
      { text: "Data esportazione", value: "export_date" },
      {
        text: "Descrizione",
        value: "export_description"
      },

      {
        text: "Nome",
        value: "shipping_first_name"
      },
      {
        text: "Cognome",
        value: "shipping_last_name"
      },
      {
        text: "Business",
        value: "shipping_company"
      },
      {
        text: "Email",
        value: "email"
      },
      {
        text: "Tracking rimessa",
        value: "custom_tracking",
        align: "middle"
      },
      {
        text: "Acquisizione MBE",
        value: "custom_tracking_registered",
        align: "middle"
      },
      {
        text: "Tracking corriere",
        value: "courier_tracking",
        align: "left",
        sortable: false
      },
      {
        text: "Ultimo stato",
        value: "tracking_state",
        width: "300px",
        align: "left",
        sortable: false
      },

      {
        text: "Consegnato",
        value: "delivered",
        align: "center"
      },
      {
        text: "Tickets",
        value: "tickets",
        align: "center"
      },
      { text: "", align: "center", sortable: false, value: "actions" }
    ];

    this.fetchFilters();
  },

  computed: {
    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      }
    },

    getPeriodFilters: {
      get: function() {
        return staticData.filtersPeriods();
      }
    }
  },

  watch: {
    exportsOptions: {
      handler() {
        clearTimeout(this._productsDebounceTimer);
        this._productsDebounceTimer = setTimeout(() => {
          this.reloadExports();
        }, 300);
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadExports();
        }, 300);
      },

      deep: true
    }
  },

  methods: {
    getExceptionColor(item) {
      if (item.exception_state == 0) {
        return "green";
      }
      if (item.exception_severity == 1) {
        return "secondary lighten-1";
      } else if (item.exception_severity == 2) {
        return "yellow darken-2";
      } else {
        return "red";
      }
    },

    showTicket: function(item) {
      this.$refs.ticketDetail
        .show(item.id, "export_item_id")
        .then(res => {
          if (res) {
            if (res.content) {
              ticketManager.addTicket("export_item_id", res).then(() => {
                item.exception_state = 1;
              });
            } else if (res.closed) {
              ticketManager.closeTicket("export_item_id", res).then(() => {
                item.exception_state = 0;
              });
            }
          }
        })
        .catch(() => {});
    },

    openTicket: function(item) {
      this.$refs.ticketCreator
        .show(false)
        .then(res => {
          ticketManager
            .openTicket("export_item_id", item.id, res)
            .then(res => {
              item.exception_id = res.id;
              item.exception_title = res.title;
              item.exception_severity = res.severity;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    removeTicket: function(item) {
      this.$refs.msgBox
        .show("Confermare la rimozione del ticket", "Rimozione ticket")
        .then(() => {
          ticketManager
            .removeTicket("export_item_id", item.id)
            .then(() => {
              item.exception_id = null;
              item.exception_title = null;
              item.exception_severity = null;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    fetchFilters: function() {
      this.fetchingFilters = true;
      const ctx = {
        sortBy: "start_date",
        sortDesc: true
      };
      subscriptionsManager
        .fetchShipments(ctx)
        .then(res => {
          this.shipments = [
            { id: null, name: this.$t("gbl-all-female") },
            ...res.items
          ];

          this.fetchingFilters = false;
        })
        .catch(err => {
          console.log(err);
          this.fetchingFilters = false;
        });
    },

    reloadExports: function() {
      this.exports = [];
      this.totalExports = 0;
      this.loadingExports = true;
      this.fetchExports()
        .then(data => {
          this.exports = data.items;
          this.totalExports = data.totalCount;
          this.errorTickets = data.errorTickets;
          this.warningTickets = data.warningTickets;
          this.noticeTickets = data.noticeTickets;
          this.loadingExports = false;
        })
        .catch(err => {
          console.log(err);
          this.loadingExports = false;
        });
    },

    fetchExports() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.exportsOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        subscriptionsManager
          .fetchExportsDetails(ctx)
          .then(result => {
            this.loading = false;
            resolve(result);
          })
          .catch(err => {
            this.loading = false;
            reject(err);
          });
      });
    },

    fetchCourierTracking(item) {
      if (item.courier_company && item.courier_tracking) {
        if (item.courier_company == "FedEx") {
          return (
            "<span style='font-weight:bold'>" +
            item.courier_company +
            "</span><BR>" +
            "<a class='linked_text' target='_blank' href='https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=" +
            item.courier_tracking +
            "'>" +
            item.courier_tracking +
            "</a>"
          );
        } else if (item.courier_company == "UPS") {
          return (
            "<span style='font-weight:bold'>" +
            item.courier_company +
            "</span><BR>" +
            "<a class='linked_text' target='_blank' href='https://www.ups.com/track?tracknum=" +
            item.courier_tracking +
            "'>" +
            item.courier_tracking +
            "</a>"
          );
        } else {
          return (
            "<span style='font-weight:bold'>" +
            item.courier_company +
            "</span><BR>" +
            item.courier_tracking
          );
        }
      }
      return null;
    },

    fetchTrackingState(item) {
      if (item.courier_company == "UPS") {
        let state = JSON.parse(item.tracking_state);

        if (state[0]) {
          let ret = item.tracking_state;
          try {
            ret =
              "<span style='font-weight:bold'>" +
              state[0].status.description +
              "</span><BR>" +
              state[0].date.substring(0, 4) +
              "-" +
              state[0].date.substring(4, 6) +
              "-" +
              state[0].date.substring(6, 8) +
              " " +
              state[0].time.substring(0, 2) +
              ":" +
              state[0].time.substring(2, 4) +
              ":" +
              state[0].time.substring(4, 6);
          } catch (err) {
            if (state[0] && state[0].status && state[0].status.description)
              ret =
                "<span style='font-weight:bold'>" +
                state[0].status.description +
                "</span><BR>";
          }

          return ret;
        }
      } else if (item.courier_company == "FedEx") {
        let state = JSON.parse(item.tracking_state);
        if (state[0] && state[0].date) {
          let date = new Date(Date.parse(state[0].date));

          return (
            "<span style='font-weight:bold'>" +
            state[0].eventDescription +
            "</span><BR>" +
            (date ? date : "")
          );
        }
      }

      return null;
    }
  }
};
</script>

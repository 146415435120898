var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('messageBox',{ref:"msgBox"}),_c('ShipmentEditor',{ref:"shipmentEditor"}),_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dropbox")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"20px"},attrs:{"align":"center","cols":"11"}},[_vm._v("Gestione spedizioni")])],1)]},proxy:true}])},[_c('v-card-text',[_c('div',{staticStyle:{"height":"20px"}}),_c('v-row',{staticClass:"text-center",attrs:{"align":"center","justify":"center"}},[(_vm.$store.state.hasRight('262'))?_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.addShipment()}}},[_vm._v("Aggiungi spedizione")]):_vm._e()],1),_c('v-data-table',{attrs:{"items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.shipmentsHeaders,"items":_vm.shipments,"options":_vm.shipmentsOptions,"server-items-length":_vm.totalShipments,"loading":_vm.loadingShipments,"footer-props":_vm.dataTableFooterOptions,"item-key":"id"},on:{"update:options":function($event){_vm.shipmentsOptions=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.state.hasRight('263'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.editShipment(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modifica spedizione")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.state.hasRight('264'))?_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteShipment(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Rimuovi spedizione")])])]}},{key:"item.reference_dates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.start_date))+" - "+_vm._s(_vm._f("toLocaleDate")(item.end_date))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"800","value":_vm.value}},[_c('SetString',{ref:"renamePermission"}),_c('MessageBox',{ref:"msgBox"}),_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-application")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.$t("appeditor-title")))]),_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('v-form',{ref:"app-editor-form",staticClass:"mt-5"},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('ValidationProvider',{attrs:{"immediate":_vm.editingApplication,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('appeditor-hint-name'),"prepend-icon":"mdi-circle-edit-outline","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.app.name),callback:function ($$v) {_vm.$set(_vm.app, "name", $$v)},expression:"app.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":_vm.editingApplication,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('appeditor-hint-description'),"prepend-icon":"mdi-circle-edit-outline","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.app.description),callback:function ($$v) {_vm.$set(_vm.app, "description", $$v)},expression:"app.description"}})]}}],null,true)}),_c('v-btn',{attrs:{"disabled":!_vm.editingApplication,"color":"primary"},on:{"click":function($event){return _vm.addPermission()}}},[_vm._v(_vm._s(_vm.$t("appeditor-add-right")))]),_c('div',{staticStyle:{"height":"10px"}}),_c('v-data-table',{staticClass:"grid-table",attrs:{"items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.permissions,"options":_vm.options,"server-items-length":_vm.totalPermissions,"loading":_vm.fetchingData,"footer-props":_vm.dataTableFooterOptions},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.editPermission(item)}}},on),[_c('v-icon',[_vm._v("mdi-qrcode-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("appmng-modify-app")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deletePermission(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("appmng-remove-app")))])])]}}],null,true)}),_c('v-btn',{attrs:{"width":"120","disabled":invalid || !validated,"color":"orange"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("gbl-ok")))]),_c('v-btn',{attrs:{"width":"120","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("gbl-cancel")))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"justify-center":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('messageBox',{ref:"msgBox"}),_c('groupEditor',{ref:"groupEditor",on:{"groupConfigured":_vm.handleGroup},model:{value:(_vm.groupEditorDialog),callback:function ($$v) {_vm.groupEditorDialog=$$v},expression:"groupEditorDialog"}}),_c('v-flex',{attrs:{"md12":""}},[_c('v-btn',{attrs:{"disabled":!_vm.$store.state.hasRight('33'),"color":"primary"},on:{"click":function($event){return _vm.addGroup()}}},[_vm._v(_vm._s(_vm.$t("groupmng-add")))]),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","icon":"mdi-account","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light card-header"},[_vm._v(" "+_vm._s(_vm.$t("groupmng"))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"mr-auto pl-1",staticStyle:{"max-width":"250px"},attrs:{"value":_vm.filter,"append-icon":"mdi-magnify","label":_vm.$t('gbl-search'),"hide-details":"","single-line":""},on:{"change":function (v) { return (_vm.filter = v); }}}),_c('div',{staticClass:"vertical-spacer"}),_c('v-data-table',{staticClass:"grid-table",attrs:{"items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.groups,"options":_vm.options,"server-items-length":_vm.totalGroups,"loading":_vm.loading,"footer-props":_vm.dataTableFooterOptions},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.rights_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rights ? item.rights.split(",").length : 0)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.$store.state.hasRight('34'),"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("groupmng-modify-group")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.$store.state.hasRight('35'),"x-small":"","text":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("usrmng-remove-user")))])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <messageBox ref="msgBox" />
    <OrderEditor ref="orderEditor" />
    <ticketCreator ref="ticketCreator" />
    <ticketDetail ref="ticketDetail" />

    <v-dialog
      ref="date_picker"
      v-model="date_modal"
      :return-value.sync="set_date_model"
      width="290px"
    >
      <v-date-picker
        @input="
          $refs.date_picker.save(set_date_model);
          onDateSelected();
        "
        v-model="set_date_model"
        scrollable
      >
      </v-date-picker>
    </v-dialog>

    <FileUploader
      ref="fileUploader"
      @uploadCompleted="handleUploadCompleted"
      v-model="fileUploader"
    />

    <v-dialog
      v-model="stripeInvoicesDialog"
      ref="stripeInvoicesDialog"
      persistent
      overlay-opacity="0.6"
      max-width="800"
    >
      <!-- @input is required to emit emit input if clicked outside unless we use persistent -->
      <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
          <v-flex md12>
            <base-material-card class="pa-0" color="primary">
              <template v-slot:heading>
                <v-row class="pa-0">
                  <v-col cols="1"></v-col>
                  <v-col
                    align="center"
                    cols="10"
                    class="card-title-text"
                    style="font-size: 20px"
                    >{{ "Seleziona fattura da Stripe" }}</v-col
                  >
                  <v-col cols="1">
                    <v-btn icon>
                      <v-icon @click="stripeInvoicesDialog = false" large
                        >mdi-close</v-icon
                      >
                    </v-btn></v-col
                  >
                </v-row>
              </template>
              <v-card-text>
                <v-row dense justify="center">
                  <v-col class="text-center">
                    <v-text-field
                      dense
                      outlined
                      label="Ricerca in email e nome cliente"
                      :disabled="loadingInvoices"
                      :value="stripeFilters.filter"
                      @change="v => (stripeFilters.filter = v)"
                      clearable
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-data-table
                  :items-per-page="perPage"
                  :must-sort="true"
                  :headers="stripeInvoicesHeaders"
                  :items="invoices"
                  :options.sync="stripeInvoicesOptions"
                  :server-items-length="totalInvoices"
                  :loading="loadingInvoices"
                  :footer-props="dataTableFooterOptions"
                >
                  <template v-slot:item.document_number="{ item }">
                    {{ item.doc_number + " / " + item.doc_year }}
                  </template>

                  <template v-slot:item.invoice_date="{ item }">
                    {{ item.invoice_date | toLocaleDate }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div style="min-width: 70px;">
                      <v-tooltip bottom
                        ><template v-slot:activator="{ on }"
                          ><v-btn
                            x-small
                            text
                            icon
                            color="secondary"
                            @click.stop="selectInvoice(item)"
                            v-on="on"
                            ><v-icon>mdi-target</v-icon></v-btn
                          ></template
                        ><span>Seleziona</span></v-tooltip
                      >
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </base-material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>

    <base-material-card class="pa-0" color="primary">
      <template v-slot:heading>
        <v-row class="pa-0">
          <v-col cols="1" class="pa-0"
            ><v-icon large>mdi-truck-fast-outline</v-icon></v-col
          >
          <v-col
            cols="9"
            align="center"
            class="pa-0 card-title-text"
            style="font-size: 20px"
            >Gestione ordini manuali <br />(Ordini non esportati:
            {{ notExportedTotalOrders }})</v-col
          >
          <v-col align="right"
            >Tickets aperti:
            <v-chip class="mr-1" color="red" small>{{ errorTickets }}</v-chip
            ><v-chip class="mr-1" color="orange" small>{{
              warningTickets
            }}</v-chip
            ><v-chip color="green" small>{{ noticeTickets }}</v-chip></v-col
          >
        </v-row>
      </template>

      <v-card-text>
        <v-row dense justify="center">
          <v-col class="text-center">
            <v-text-field
              dense
              outlined
              label="Ricerca in email e nome cliente"
              :disabled="loadingOrders"
              :value="filters.filter"
              @change="v => (filters.filter = v)"
              clearable
              hide-details
            ></v-text-field>
          </v-col>

          <v-col>
            <v-select
              dense
              v-model="filters.filterByOrderState"
              outlined
              item-text="text"
              item-value="value"
              :items="ordersStates"
              label="Stato ordini"
              :disabled="loadingOrders"
              hide-details
            />
          </v-col>
          <v-col>
            <v-select
              :disabled="loadingOrders"
              class="minfield"
              outlined
              dense
              label="Tickets"
              v-model="filters.filterByTickets"
              :items="ticketsFilters"
              item-text="name"
              item-value="id"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-select
              dense
              v-model="filters.filterByDateType"
              outlined
              item-text="text"
              item-value="value"
              :items="dateTypes"
              label="Tipo filtro data"
              :disabled="loadingOrders"
              hide-details
            />
          </v-col>

          <v-col>
            <v-select
              class="minfield"
              outlined
              dense
              label="Periodo"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters"
              item-text="name"
              item-value="id"
              :disabled="loadingOrders"
            >
            </v-select>
          </v-col>
          <v-col v-if="filters.filterByPeriod == 13">
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="start_date_formatted"
                  label="Data iniziale"
                  :disabled="loadingOrders"
                  readonly
                  v-on="on"
                  clearable
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col v-if="filters.filterByPeriod == 13">
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  outlined
                  v-model="end_date_formatted"
                  label="Data finale"
                  :disabled="loadingOrders"
                  readonly
                  clearable
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <div style="height:20px" />
        <v-row class="text-center" align="center" justify="center"
          ><v-btn
            v-if="$store.state.hasRight('257')"
            v-on:click="addOrder()"
            color="primary"
            >Aggiungi ordine</v-btn
          >
          <v-btn
            v-if="$store.state.hasRight('258')"
            :disabled="selectedOrders.length < 1"
            v-on:click="exportSelectedMos()"
            color="primary"
            >Esporta selezione</v-btn
          >
        </v-row>
        <div style="height:20px" />

        <v-data-table
          :items-per-page="perPage"
          :must-sort="true"
          :headers="ordersHeaders"
          :items="orders"
          :options.sync="ordersOptions"
          :server-items-length="totalOrders"
          :loading="loadingOrders"
          :footer-props="dataTableFooterOptions"
          item-key="id"
          show-select
          v-model="selectedOrders"
        >
          <template v-slot:item.actions="{ item }">
            <div style="min-width: 128px;">
              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.hasRight('259')"
                    x-small
                    text
                    icon
                    color="secondary"
                    @click.stop="editOrder(item)"
                    v-on="on"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  ></template
                ><span>Modifica ordine</span></v-tooltip
              >

              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.hasRight('260')"
                    x-small
                    text
                    icon
                    color="error"
                    @click.stop="deleteOrder(item.id)"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  ></template
                ><span>Rimuovi ordine</span></v-tooltip
              >

              <v-tooltip bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.hasRight('258')"
                    x-small
                    text
                    icon
                    color="green"
                    @click.stop="exportMos(item)"
                    v-on="on"
                    ><v-icon>mdi-truck-delivery</v-icon></v-btn
                  ></template
                ><span>Esporta MOS</span></v-tooltip
              >
            </div>
          </template>
          <template v-slot:item.tickets="{ item }">
            <div style="min-width: 128px;">
              <v-tooltip
                v-if="!item.exception_id && $store.state.hasRight('282')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="secondary"
                    @click.stop="openTicket(item)"
                    v-on="on"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  ></template
                ><span>{{ "Apri eccezzione" }}</span></v-tooltip
              >
              <v-tooltip
                v-if="item.exception_id && $store.state.hasRight('283')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    :color="getExceptionColor(item)"
                    @click.stop="showTicket(item)"
                    v-on="on"
                    ><v-icon>mdi-alert</v-icon></v-btn
                  ></template
                ><span>{{ item.exception_title }}</span></v-tooltip
              >
              <v-tooltip
                v-if="item.exception_id && $store.state.hasRight('284')"
                bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="secondary"
                    @click.stop="removeTicket(item)"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  ></template
                ><span>{{ "Rimuovi eccezzione" }}</span></v-tooltip
              >
            </div>
          </template>
          <template v-slot:item.shipped="{ item }">
            <v-icon v-if="item.shipped" color="green"
              >mdi-check-circle-outline</v-icon
            >
          </template>
          <template v-slot:item.exportable="{ item }">
            <v-icon v-if="item.exportable" color="green"
              >mdi-check-circle-outline</v-icon
            >
          </template>
          <template v-slot:item.invoice_required="{ item }">
            <span>
              <v-icon
                v-if="item.invoice_required && !item.invoice_path"
                color="red"
                >mdi-alert</v-icon
              >

              <v-tooltip bottom
                ><template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    v-if="item.invoice_required && item.invoice_path"
                    color="green"
                  ></v-icon></template
                ><span>{{
                  item.invoice_path
                    ? basename(item.invoice_path)
                    : "Fattura da caricare"
                }}</span></v-tooltip
              >

              <v-tooltip v-if="item.invoice_required && item.invoice_path" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    x-small
                    text
                    icon
                    color="green"
                    @click.stop="downloadInvoice(item)"
                    v-on="on"
                    ><v-icon>mdi-arrow-down</v-icon></v-btn
                  ></template
                ><span>{{ "Scarica fattura" }}</span></v-tooltip
              >

              <v-tooltip v-if="item.invoice_required" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.hasRight('259')"
                    x-small
                    text
                    icon
                    :color="item.invoice_path ? 'green' : 'secondary'"
                    @click.stop="uploadInvoice(item)"
                    v-on="on"
                    ><v-icon>mdi-arrow-up</v-icon></v-btn
                  ></template
                ><span>{{
                  item.invoice_path
                    ? "Sostituisci fattura"
                    : "Fattura da caricare"
                }}</span></v-tooltip
              >
              <v-tooltip v-if="item.invoice_required" bottom
                ><template v-slot:activator="{ on }"
                  ><v-btn
                    v-if="$store.state.hasRight('259')"
                    text
                    icon
                    :color="item.invoice_path ? 'blue' : 'blue'"
                    @click.stop="pickStripeInvoice(item)"
                    v-on="on"
                    >S</v-btn
                  ></template
                ><span>{{
                  item.invoice_path
                    ? "Sostituisci da Stripe"
                    : "Seleziona da Stripe"
                }}</span></v-tooltip
              ></span
            >
          </template>

          <template v-slot:item.order_date="{ item }">
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('259')"
                  x-small
                  text
                  icon
                  color="secondary"
                  @click.stop="setOrderDate(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>Imposta data ordine</span></v-tooltip
            >
            {{ item.order_date | toLocaleDate }}
          </template>

          <template v-slot:item.export_date="{ item }">
            <v-tooltip bottom
              ><template v-slot:activator="{ on }"
                ><v-btn
                  v-if="$store.state.hasRight('259')"
                  x-small
                  text
                  icon
                  color="secondary"
                  @click.stop="setExportDate(item)"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                ></template
              ><span>Imposta data esportazione</span></v-tooltip
            >
            {{ item.export_date | toLocaleDate }}
            <v-tooltip v-if="daysExpired(item)" bottom
              ><template v-slot:activator="{ on }"
                ><v-icon color="red" v-on="on">mdi-alert</v-icon></template
              ><span>Scaduti 30 gg da inserimento</span></v-tooltip
            >
          </template>

          <template v-slot:item.custom_tracking_registered="{ item }">
            <v-icon color="green" v-if="item.custom_tracking_registered"
              >mdi-check-circle-outline</v-icon
            >
          </template>

          <template v-slot:item.courier_tracking="{ item }">
            <span v-html="fetchCourierTracking(item)" />
          </template>

          <template v-slot:item.tracking_state="{ item }">
            <span v-html="fetchTrackingState(item)" />
          </template>

          <template v-slot:item.delivered="{ item }">
            <v-icon color="green" v-if="item.delivered"
              >mdi-check-circle-outline</v-icon
            >
          </template>

          <template v-slot:item.address="{ item }">
            {{ item.address }} <BR /> {{ item.city }} {{ item.country }}
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <div style="height:20px" />
  </div>
</template>

<script>
import OrderEditor from "@/components/OrderEditor";
import FileUploader from "@/components/FileUploader.vue";
import staticData from "@/apis/staticData";
import ordersManager from "@/apis/orders";
import invoicesManager from "@/apis/invoices";
import fileManager from "@/apis/filemanager";
import messageBox from "@/components/MessageBox";
import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("1234567890ABCDEFGHILMNOPQRSTUVZKYJWX", 6);

import ticketDetail from "@/components/TicketDetail";
import ticketCreator from "@/components/TicketCreator";
import ticketManager from "@/apis/tickets";

export default {
  data() {
    return {
      dataTableFooterOptions: staticData.dataTableFooterOptions,
      stripeInvoicesHeaders: [],
      selectedOrders: [],
      orders: [],
      totalOrders: 0,
      notExportedTotalOrders: 0,
      errorTickets: "...",
      warningTickets: "...",
      noticeTickets: "...",
      loadingOrders: false,
      fileUploader: false,
      date_start_modal: false,
      date_end_modal: false,
      set_date_model: null,
      date_modal: null,
      copyingInvoice: false,
      stripeInvoicesDialog: false,
      stripeInvoicesDialogItem: null,
      perPage: staticData.defaultPerPage,
      ordersOptions: {
        sortBy: ["order_date"],
        sortDesc: [false],
        itemsPerPage: staticData.defaultPerPage
      },

      stripeInvoicesOptions: {
        sortBy: ["event_date"],
        sortDesc: [false],
        itemsPerPage: 10
      },

      invoices: [],
      totalInvoices: 0,
      loadingInvoices: false,

      filters: {
        filter: null,
        filterByOrderState: null,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
        filterByPeriod: 5,
        filterByDateType: 1,
        filterByTickets: null
      },
      ticketsFilters: [
        { id: null, name: this.$t("gbl-all-male") },
        { id: 1, name: "Note" },
        { id: 2, name: "Allerta" },
        { id: 3, name: "Gravi" }
      ],
      stripeFilters: {
        filter: null
      },

      dateTypes: [
        {
          text: "Data inserimento",
          value: 1
        },
        {
          text: "Data esportazione",
          value: 2
        }
      ],

      ordersStates: [
        {
          text: "TUTTI",
          value: null
        },
        {
          text: "In attesa",
          value: 1
        },
        {
          text: "Partito",
          value: 2
        },
        {
          text: "Acquisito",
          value: 3
        },
        {
          text: "Consegnato",
          value: 4
        }
      ],

      ordersHeaders: [],
      fetchingOrders: false
    };
  },

  props: ["location"],

  computed: {
    date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(this.date_model);
      },
      set: function(v) {
        this.date_model = v;
      }
    },

    start_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateStart = v;
      }
    },

    end_date_formatted: {
      get: function() {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
      set: function(v) {
        this.filters.filterByCustomDateEnd = v;
      }
    },

    getPeriodFilters: {
      get: function() {
        return staticData.filtersPeriods();
      }
    }
  },

  components: {
    messageBox,
    OrderEditor,
    FileUploader,
    ticketCreator,
    ticketDetail
  },

  mounted() {
    console.log(this.location);
    this.ordersHeaders = [
      {
        text: "Data inserimento",
        value: "order_date"
      },
      {
        text: "Società",
        value: "company"
      },

      {
        text: "Nome cliente",
        value: "customer"
      },
      {
        text: "Email",
        value: "email"
      },
      {
        text: "Indirizzo",
        value: "address"
      },

      {
        text: "Fattura",
        value: "invoice_required",
        align: "center",
        width: 150
      },

      {
        text: "Data esportazione",
        value: "export_date"
      },
      {
        text: "Partito",
        value: "shipped",
        align: "center"
      },
      {
        text: "Esportabile",
        value: "exportable",
        align: "center"
      },
      {
        text: "Tracking rimessa",
        value: "custom_tracking",
        align: "middle"
      },
      {
        text: "Acquisizione MBE",
        value: "custom_tracking_registered",
        align: "middle"
      },
      {
        text: "Tracking corriere",
        value: "courier_tracking",
        align: "left",
        sortable: false
      },
      {
        text: "Ultimo stato",
        value: "tracking_state",
        width: "300px",
        align: "left",
        sortable: false
      },

      {
        text: "Consegnato",
        value: "delivered",
        align: "center"
      },
      {
        text: "Tickets",
        value: "tickets",
        align: "center",
        sortable: false
      },
      { text: "", align: "center", sortable: false, value: "actions" }
    ];

    this.stripeInvoicesHeaders = [
      {
        text: "Documento",
        value: "document_number"
      },
      {
        text: "Data",
        value: "invoice_date"
      },
      {
        text: "Nome",
        value: "first_name"
      },
      {
        text: "Cognome",
        value: "last_name"
      },
      { text: "", align: "center", sortable: false, value: "actions" }
    ];
  },

  watch: {
    stripeInvoicesOptions: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadInvoices(true);
        }, 300);
      },
      deep: true
    },

    stripeFilters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadInvoices(true);
        }, 300);
      },
      deep: true
    },

    ordersOptions: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadOrders(true);
        }, 300);
      },
      deep: true
    },

    filters: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          this.reloadOrders(true);
        }, 300);
      },
      deep: true
    }
  },

  methods: {
    getExceptionColor(item) {
      if (item.exception_state == 0) {
        return "green";
      }
      if (item.exception_severity == 1) {
        return "secondary lighten-1";
      } else if (item.exception_severity == 2) {
        return "yellow darken-2";
      } else {
        return "red";
      }
    },

    showTicket: function(item) {
      this.$refs.ticketDetail
        .show(item.id, "manual_item_id")
        .then(res => {
          if (res) {
            if (res.content) {
              ticketManager.addTicket("manual_item_id", res).then(() => {
                item.exception_state = 1;
              });
            } else if (res.closed) {
              ticketManager.closeTicket("manual_item_id", res).then(() => {
                item.exception_state = 0;
              });
            }
          }
        })
        .catch(() => {});
    },

    openTicket: function(item) {
      this.$refs.ticketCreator
        .show(false)
        .then(res => {
          ticketManager
            .openTicket("manual_item_id", item.id, res)
            .then(res => {
              item.exception_id = res.id;
              item.exception_title = res.title;
              item.exception_severity = res.severity;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    removeTicket: function(item) {
      this.$refs.msgBox
        .show("Confermare la rimozione del ticket", "Rimozione ticket")
        .then(() => {
          ticketManager
            .removeTicket("manual_item_id", item.id)
            .then(() => {
              item.exception_id = null;
              item.exception_title = null;
              item.exception_severity = null;
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    selectInvoice(item) {
      this.copyingInvoice = true;
      let invoice_path =
        "Documents/Manuali/" + this.$store.state.backend + "/Fatture/";
      ordersManager
        .copyInvoiceToManualOrder(
          this.stripeInvoicesDialogItem.id,
          item.doc_number,
          item.doc_year,
          invoice_path
        )
        .then(() => {
          this.copyingInvoice = false;
          this.stripeInvoicesDialog = false;
          this.reloadOrders(true);
        });
    },

    stripeInvoiceReset() {
      return new Promise(resolve => {
        resolve();
      });
    },

    pickStripeInvoice(item) {
      this.stripeInvoicesDialogItem = item;
      this.stripeInvoiceReset().then(() => {
        this.reloadInvoices();
        this.stripeInvoicesDialog = true;
      });
    },
    daysExpired(item) {
      if (!item.export_date && item.elapsed_days > 30) return true;
      return false;
    },

    basename(path) {
      return path.split("/").reverse()[0];
    },

    onDateSelected() {
      let func = ordersManager.setShipmentDate;
      if (this.date_context == "setShipmentDate")
        func = ordersManager.setShipmentDate;
      else if (this.date_context == "setOrderDate")
        func = ordersManager.setOrderDate;
      else if (this.date_context == "setExportDate")
        func = ordersManager.setExportDate;
      else if (this.date_context == "setDeliveryDate")
        func = ordersManager.setDeliveryDate;

      func(this.date_item.id, this.set_date_model)
        .then(() => {
          this.reloadOrders(false);
        })
        .catch(err => {
          console.log(err);
        });
    },

    setShipmentDate(item) {
      this.date_item = item;
      this.date_context = "setShipmentDate";
      this.date_model = item.shipment_date;
      this.date_modal = true;
    },

    setOrderDate(item) {
      this.date_item = item;
      this.date_context = "setOrderDate";
      this.date_model = item.shipment_date;
      this.date_modal = true;
    },
    setExportDate(item) {
      this.date_item = item;
      this.date_context = "setExportDate";
      this.date_model = item.shipment_date;
      this.date_modal = true;
    },
    setDeliveryDate(item) {
      this.date_item = item;
      this.date_context = "setDeliveryDate";
      this.date_model = item.shipment_date;
      this.date_modal = true;
    },

    addOrder() {
      let order = {
        source_company: 1,
        customer: "Nuovo cliente",
        business_address: false,
        shipped: false,
        exportable: false,
        delivered: false,
        invoice_required: false,
        custom_tracking: nanoid(6).toUpperCase(),
        email: "",
        contact_phone: "",
        first_name: "",
        last_name: "",
        address: "",
        postcode: "",
        city: "",
        country: "",
        number_of_bottles: "",
        location: this.location
      };

      this.$refs.orderEditor
        .show(order, false)
        .then(order => {
          ordersManager
            .addOrder(order)
            .then(() => {
              this.reloadOrders(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    editOrder(item) {
      let order = Object.assign({}, item);
      if (order.shipment_value)
        order.shipment_value = order.shipment_value
          .toFixed(2)
          .replace(".", ",");
      this.$refs.orderEditor
        .show(order, true)
        .then(order => {
          ordersManager
            .editOrder(order)
            .then(() => {
              this.reloadOrders(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    deleteOrder(id) {
      this.$refs.msgBox
        .show(
          "Vuoi davvero confermare la cancellazione dell' ordine ?",
          "Conferma operazione"
        )
        .then(() => {
          ordersManager
            .removeOrder(id)
            .then(() => {
              this.reloadOrders(false);
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    exportMos: function(item) {
      ordersManager
        .flagOrderShipped(item.id)
        .then(() => {
          ordersManager.downloadOrderMOS(item.id);
          this.reloadOrders(false);
        })
        .catch(err => {
          console.log(err);
        });
    },

    exportSelectedMos: async function() {
      let ids = [];
      for (let x in this.selectedOrders) {
        await ordersManager.flagOrderShipped(this.selectedOrders[x].id);
        ids.push(this.selectedOrders[x].id);
      }

      ordersManager.downloadOrderMOS(ids.join(","));
      this.reloadOrders(false);
    },

    reloadInvoices: function(showLoader) {
      this.invoices = [];
      this.totalInvoices = 0;
      this.fetchInvoices(showLoader)
        .then(data => {
          this.invoices = data.transactions;
          this.totalInvoices = data.count;
        })
        .catch(err => {
          console.log(err);
        });
    },

    reloadOrders: function(showLoader) {
      this.fetchOrders(showLoader)
        .then(data => {
          this.orders = data.items;
          this.totalOrders = data.totalCount;
          this.notExportedTotalOrders = data.notExportedCount;
          this.errorTickets = data.errorTickets;
          this.warningTickets = data.warningTickets;
          this.noticeTickets = data.noticeTickets;
        })
        .catch(err => {
          console.log(err);
        });
    },

    handleUploadCompleted: function(payload) {
      ordersManager
        .updateItemInvoice(payload.item.id, payload.path)
        .then(() => {
          this.reloadOrders(false);
        });
    },

    downloadInvoice: function(item) {
      let invoice_path = item.invoice_path;

      fileManager
        .downloadFile(invoice_path, invoice_path)
        .then(() => {})
        .catch(err => {
          console.log(err);
        });
    },

    uploadInvoice: function(item) {
      let invoice_path =
        "Documents/Manuali/" + this.$store.state.backend + "/Fatture/";
      this.$refs.fileUploader.setPath(item, invoice_path);
      this.fileUploader = true;
    },

    fetchInvoices: function(showLoader) {
      if (showLoader) this.loadingInvoices = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.stripeInvoicesOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.stripeFilters.filter;
        ctx.filters = this.stripeFilters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        ctx.filters.filterOnlyInvoices = true;
        ctx.filters.filterNoWC = true;
        invoicesManager
          .fetchTransactions(ctx)
          .then(result => {
            if (showLoader) this.loadingInvoices = false;
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loadingInvoices = false;
            reject(err);
          });
      });
    },

    fetchOrders: function(showLoader) {
      if (showLoader) this.loading = true;
      return new Promise((resolve, reject) => {
        let ctx = Object.assign({}, this.ordersOptions);
        ctx.currentPage = ctx.page;
        ctx.perPage = ctx.itemsPerPage != -1 ? ctx.itemsPerPage : null;
        ctx.filter = this.filter;
        ctx.filters = this.filters;
        ctx.sortBy = ctx.sortBy[0];
        ctx.sortDesc = ctx.sortDesc[0];
        ctx.location = this.location;
        ordersManager
          .fetchOrders(ctx)
          .then(result => {
            if (showLoader) this.loading = false;
            resolve(result);
          })
          .catch(err => {
            if (showLoader) this.loading = false;
            reject(err);
          });
      });
    },
    fetchCourierTracking(item) {
      if (item.courier_company && item.courier_tracking) {
        if (item.courier_company == "FedEx") {
          return (
            "<span style='font-weight:bold'>" +
            item.courier_company +
            "</span><BR>" +
            "<a class='linked_text' target='_blank' href='https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=" +
            item.courier_tracking +
            "'>" +
            item.courier_tracking +
            "</a>"
          );
        } else if (item.courier_company == "UPS") {
          return (
            "<span style='font-weight:bold'>" +
            item.courier_company +
            "</span><BR>" +
            "<a class='linked_text' target='_blank' href='https://www.ups.com/track?tracknum=" +
            item.courier_tracking +
            "'>" +
            item.courier_tracking +
            "</a>"
          );
        } else {
          return (
            "<span style='font-weight:bold'>" +
            item.courier_company +
            "</span><BR>" +
            item.courier_tracking
          );
        }
      }
      return null;
    },

    fetchTrackingState(item) {
      if (item.courier_company == "UPS") {
        let state = JSON.parse(item.tracking_state);
        let ret = item.tracking_state;
        if (state[0]) {
          try {
            ret =
              "<span style='font-weight:bold'>" +
              state[0].status.description +
              "</span><BR>" +
              state[0].date.substring(0, 4) +
              "-" +
              state[0].date.substring(4, 6) +
              "-" +
              state[0].date.substring(6, 8) +
              " " +
              state[0].time.substring(0, 2) +
              ":" +
              state[0].time.substring(2, 4) +
              ":" +
              state[0].time.substring(4, 6);
          } catch (err) {
            if (state[0] && state[0].status && state[0].status.description)
              ret =
                "<span style='font-weight:bold'>" +
                state[0].status.description +
                "</span><BR>";
          }

          return ret;
        }
      } else if (item.courier_company == "FedEx") {
        let state = JSON.parse(item.tracking_state);
        if (state[0] && state[0].date) {
          let date = new Date(Date.parse(state[0].date));

          return (
            "<span style='font-weight:bold'>" +
            state[0].eventDescription +
            "</span><BR>" +
            (date ? date : "")
          );
        }
      }

      return null;
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"justify-center":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('messageBox',{ref:"msgBox"}),_c('userEditor',{ref:"userEditor",on:{"userConfigured":_vm.handleUser},model:{value:(_vm.userEditorDialog),callback:function ($$v) {_vm.userEditorDialog=$$v},expression:"userEditorDialog"}}),_c('v-flex',{attrs:{"md12":""}},[(_vm.$store.state.hasRight('20'))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addUser()}}},[_vm._v(_vm._s(_vm.$t("usrmng-add")))]):_vm._e(),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","icon":"mdi-account","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light card-header"},[_vm._v(" "+_vm._s(_vm.$t("usrmng"))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"mr-auto pl-1",staticStyle:{"max-width":"250px"},attrs:{"value":_vm.filter,"append-icon":"mdi-magnify","label":_vm.$t('gbl-search'),"hide-details":"","single-line":""},on:{"change":function (v) { return (_vm.filter = v); }}}),_c('div',{staticClass:"vertical-spacer"}),_c('v-data-table',{staticClass:"grid-table",attrs:{"items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"footer-props":_vm.dataTableFooterOptions},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [(_vm.checkGravatar(item) && _vm.getGravatar(item))?_c('v-avatar',{attrs:{"size":"36px"}},[_c('v-img',{attrs:{"src":_vm.getGravatar(item),"alt":_vm.getUsername(item)}})],1):(_vm.getProfileLetters(item))?_c('v-avatar',{attrs:{"color":"blue","size":"36px"}},[_c('span',{staticClass:"profile-text"},[_vm._v(_vm._s(_vm.getProfileLetters(item)))])]):_vm._e(),_vm._v(" "+_vm._s(item.first_name)+" ")]}},{key:"item.rights",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRight(item))+" ")]}},{key:"item.groups",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatGroups(item))}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.hasRight('21'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!item.can_be_edited || item.id == _vm.$store.state.user.id,"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("usrmng-modify-user")))])]):_vm._e(),(!item.banned && _vm.$store.state.hasRight('22'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!item.can_be_edited || item.id == _vm.$store.state.user.id,"x-small":"","text":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.banItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-off")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("usrmng-ban-user")))])]):_vm._e(),(item.banned && _vm.$store.state.hasRight('22'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!item.can_be_edited || item.id == _vm.$store.state.user.id,"x-small":"","text":"","icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.banItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("usrmng-unban-user")))])]):_vm._e(),(
                item.can_be_deleted &&
                  item.id != _vm.$store.state.user.id &&
                  item.orders_count == 0 &&
                  _vm.$store.state.hasRight('23')
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("usrmng-remove-user")))])]):_vm._e(),(
                (!item.can_be_deleted ||
                  item.id == _vm.$store.state.user.id ||
                  item.orders_count > 0) &&
                  _vm.$store.state.hasRight('23')
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":true,"x-small":"","text":"","icon":"","color":"error"}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("usrmng-remove-user")))])]):_vm._e(),(_vm.$store.state.hasRight('114'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"purple"},on:{"click":function($event){$event.stopPropagation();return _vm.impersonate(item)}}},on),[_c('v-icon',[_vm._v("mdi-ghost")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("usrmng-impersonate")))])]):_vm._e()]}},{key:"item.banned",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.banned ? _vm.$t("gbl-no") : _vm.$t("gbl-yes"))+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }